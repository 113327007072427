(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/marketplace/components/team-list-page/assets/javascripts/team-list-onboarding.js') >= 0) return;  svs.modules.push('/modules/marketplace/components/team-list-page/assets/javascripts/team-list-onboarding.js');
'use strict';

const {
  WidgetArea
} = svs.components.lbUtils.widgetArea;
const TeamListOnboarding = () => React.createElement("div", {
  className: "bg-sport-50 marketplace-full-width padding-bottom-2",
  "data-testid": "qa-mkp-team-welcome"
}, React.createElement(WidgetArea, {
  className: "marketplace-teams-widget-area",
  widgetArea: "teamsWidgetAreaTop"
}), React.createElement("div", {
  className: "marketplace-content-wrapper"
}, React.createElement(WidgetArea, {
  className: "marketplace-teams-widget-area",
  widgetArea: "teamsWidgetArea"
})));
setGlobal('svs.marketplace.components.teamListPage.TeamListOnboarding', TeamListOnboarding);

 })(window);